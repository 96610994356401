<template>
  <div class="profile-addresses">
    <!-- <v-container fluid> -->
    <CategoryTitle :category="category" />
    <v-list class="mt-5">
      <v-row>
        <v-col
          v-for="address in userAddresses.addresses"
          :key="address.addressId"
          cols="12"
          md="6"
        >
          <v-list-item class="px-0">
            <v-card outlined class="d-flex w-100">
              <div>
                <v-card-title>{{ address.addressName }}</v-card-title>
                <v-card-text>
                  {{ address.address1 }} {{ address.addressNumber }} -
                  {{ address.city }} ({{ address.province }})
                </v-card-text>
              </div>

              <v-spacer />

              <v-card-actions class="flex-column justify-space-around">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      large
                      icon
                      class="main-button"
                      :to="'/profile/addresses/manage/' + address.addressId"
                    >
                      <v-icon medium>$edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica indirizzo</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      large
                      v-bind="attrs"
                      v-on="on"
                      icon
                      class="secondary-icon-button"
                      @click="removeAddress(address.addressId)"
                    >
                      <v-icon>$delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina indirizzo</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>

    <div class="d-flex justify-end mt-5">
      <v-btn color="primary" large depressed to="/profile/addresses/manage">
        {{ $t("addresses.addAddressButton") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import DeliveryService from "~/service/deliveryService";
import categoryMixins from "~/mixins/category";

export default {
  name: "ProfileAddress",
  components: { CategoryTitle },
  mixins: [categoryMixins],
  data() {
    return {
      newAddress: {
        addressId: -1,
        addressName: "",
        address1: "",
        address2: "",
        addressNumber: "",
        postalcode: "",
        city: "",
        province: ""
      },
      userAddresses: []
    };
  },

  methods: {
    async fetchAddresses() {
      this.userAddresses = await DeliveryService.getWarehousesList("home");
    },
    async removeAddress(addressId) {
      const res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        await DeliveryService.removeAddress(addressId);
        await this.fetchAddresses();
      }
    },
    selectAddress(address) {
      DeliveryService.selectAddress(address.addressId).then(function() {
        //go to home page
      });
    },
    addAddress() {
      // this.$router.push({
      //   path: "/edit-addresses/",
      //   name: "/edit-addresses/",
      //   params: {
      //     address: this.newAddress,
      //     newAddress: true
      //   }
      // });
    }
  },
  mounted() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchAddresses();
  }
};
</script>
<style lang="scss">
.profile-addresses {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .main-button {
      height: 30px !important;
      width: 30px !important;
      .v-icon {
        font-size: 24px;
        width: 24px;
        height: 24px;
      }
    }
    .secondary-icon-button {
      height: 30px !important;
      width: 30px !important;
      .v-icon {
        font-size: 24px;
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
